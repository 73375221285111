#nav {
  position: fixed;
  top: 0;
  line-height: 3.5em;
  width: 100%;
  background: #00bce4;
  z-index: 2;
}

.navLink {
  font-weight: bold;
  color: black;
  padding: 12px;
  text-decoration: none;
}

.navIcons {
  position: absolute;
  right: 20px;
}

.navLogo {
  z-index: 100;
  position: absolute;
  left: 20px;
  font-family: 'Marck Script', cursive;
  font-size: 2em;
  color: white;
}

.active {
  color: white;
  padding: 12px;
  text-decoration: white overline;
}

.navLink:hover {
  text-decoration: white overline;
  color: white;
}

@media (max-width:750px) {
  .navLink{
    display: none
  }
  .navIcons {
    line-height: 3.5em;
    background: #222;
    width: 100%;
    position: fixed;
    right: 0;
    top: 0;
  }
}
